import {ChromePicker} from 'react-color'
import React from 'react'

const ColorPicker = ({onColorChange}) => {
    const [color , setColor] = React.useState('#fff')

    const handleColorChange=(color)=>{
        setColor(color.hex)
        onColorChange(color.hex)
    }
  return (
    <>
        <ChromePicker color={color} onChangeComplete={handleColorChange}/>
    </>
  )
}

export default ColorPicker