import React from 'react'
import Sidebar from '../components/Sidebar'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
 faBell,
} from '@fortawesome/free-regular-svg-icons'
import {
 faCaretDown
}from '@fortawesome/free-solid-svg-icons'
//import ReportTable from '../components/ReportTable';
import { DataGrid,GridToolbar } from '@mui/x-data-grid';
import { useDemoData } from '@mui/x-data-grid-generator';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ExpensesTable from "../components/ExpensesTable";
import { getNonbillableExpense } from '../redux/slices/nonbill';
import { InfinitySpin } from "react-loader-spinner"

const Expenses = () => {
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    const VISIBLE_FIELDS = ['Expenses  ID', 'date', 'Account Name','Description','Account Paid','Currency Code','Currency ID','Total Tax','Status'];
    const { data } = useDemoData({
        
        rowLength: 10,
        maxColumns: 6,
        visibleFields: VISIBLE_FIELDS,
      });

      const[isLoading,setIsLoading] = useState(true)
      const dispatch = useDispatch();
      const isExpensesLoading = useSelector(
        (state) => state.nonbill.isExpensesLoading
      );
    
      console.log("wwwwwwwww.................................w")
      const expenses = useSelector((state) => state.nonbill.expenses);
      console.log(typeof expenses,'trtr')

      const expensesWithId = expenses.map(expense => ({
        ...expense,
        id: expense.expense_id
      }));
       

      const columns = [
        { headerName: "Account  ID", field: "account_id", sortable: true,sortbyOrder: "asc" },
        { headerName: "Tax Id", field: "tax_id", sortable: true },
        { headerName: "Payment Date", field: "payment_date", sortable: false },
        { headerName: "Description", field: "description", sortable: false },
        { headerName: "Is taxable ", field: "is_taxable", sortable: false },
        { headerName: "Amount ", field: "amount", sortable: false },
        { headerName: "Currency ID", field: "currency_id", sortable: false },
        { headerName: "Reference Number", field: "total_without_tax", sortable: false },
        { headerName: "Customer Id", field: "customer_id", sortable: false },
        { headerName: "Non Billable", field: "non_billable", sortable: false },
      ];
      

    
    useEffect(() => {
        dispatch(getNonbillableExpense())
          .then((result) => {
            setIsLoading(false)
          })
          .catch((error) => {
            console.error(error); 
            setIsLoading(false)
          });
      }, [dispatch]); 
  return (
   <>
     <Sidebar />
     {
  isLoading ? 
  <div className="flex justify-center h-screen items-center">
  <InfinitySpin width="200" color="red"/> 
  </div>:
    <div className='relative md:ml-64 bg-blueGray-100'>
    <nav className="absolute top-0 left-0 w-full">
                    <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
                        {/* Brand */}
                        <a
                            className=" text-lg  hidden lg:inline-block font-semibold"
                            href="#pablo"
                            style={{color:'#671c2d'}}
                            onClick={e => e.preventDefault()}
                        >
                            Cash flow forecast report
                        </a>
                  
                        {/* User */}
                        <FontAwesomeIcon icon={faBell} className="border px-3 py-3 rounded-full" style={{fontSize:13}} />
                    </div>
                </nav>
        <div className="py-11">
                <div className="border-t border-gray-200"></div>
              </div>

              <div className='grid grid-cols-4 gap-3 m-5'>
                  
                    <div className="relative w-full px-4 max-w-full flex-grow flex-1 text-right">
                
                                <button onClick={() => setShowModal(true)}
                                    className=" text-white active:bg-red-600 text-xs font-bold px-12 py-3 rounded outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
    
                                    style={{ transition: "all .15s ease",backgroundColor:'#df3c62' }}
                                >
                                    Show report
                                </button>
                               
                            </div>
                            
                </div>
             
                <div className=' m-6 ' style={{ height: 400, width: '93%' }}>
                {!isExpensesLoading && (
                    <DataGrid
       slots={{
          toolbar: GridToolbar,
          // Use custom FilterPanel only for deep modification
          // FilterPanel: MyCustomFilterPanel,
        }}
        
        checkboxSelection
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        {...data}
        rows={expenses} // Use expensesWithId here
        getRowId={(row) => row.expense_id} 
       columns={columns}
         initialState={{
          ...data.initialState,
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        pageSizeOptions={[5, 10, 25]}
      />
                    
                )}
     
    </div>
    
    </div>
}
   </>
  )
}

export default Expenses