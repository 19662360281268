import React from "react";
import Chart from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import { getCharts } from "../redux/slices/zoho";

const BarCharts = () => {
  const dispatch = useDispatch();
  const charts = useSelector((state) => state.zoho.charts);
  let isChartLoading = useSelector((state) => state.zoho.isChartLoading);
  let { forecastNumber, forecastPeriod } = useSelector(
    (state) => state.forecast.forecastInfo
  );

  React.useEffect(() => {
    dispatch(getCharts({forecastNumber, forecastPeriod}));
    if(isChartLoading == undefined) {
     isChartLoading = false;
    }

    let config = {
      type: "bar",
      data: {
        labels: isChartLoading ? [] : charts.months,
        datasets: [
          {
            label: 'Inflow',
            backgroundColor: "green",
            borderColor: "green",
            data: isChartLoading ? [] : charts.forecastNairaInflow,
            fill: false,
            barThickness: 15,
            borderWidth: 1,
          },
          {
            label: 'Outflow',
            fill: false,
            backgroundColor: "red",
            borderColor: "red",
            data: isChartLoading ? [] : charts.forecastNairaOutflow,
            barThickness: 15,
          },
          
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: false,
          text: "Orders Chart",
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        legend: {
          labels: {
            fontColor: "rgba(0,0,0,.4)",
          },
          align: "end",
          position: "top",
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Month",
              },
              gridLines: {
                borderDash: [2],
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.3)",
                zeroLineColor: "rgba(33, 37, 41, 0.3)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
          yAxes: [
            {
              position: 'top',
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Value",
              },
              gridLines: {
                borderDash: [2],
                drawBorder: false,
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.2)",
                zeroLineColor: "rgba(33, 37, 41, 0.15)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
              
            },
          ],
          borderColor: [
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)'
          ],
        },
      },
    };
    let ctx = document.getElementById("bar-chart").getContext("2d");
    window.myBar = new Chart(ctx, config);
  }, [dispatch]);
  return (
    <>
      <div className="w-full xl:w-10/12 px-4">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 border rounded">
          <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
            <div className="flex flex-wrap items-center">
            </div>
          </div>
          <div className="p-4 flex-auto">
            {/* Chart */}
            <div className="relative" style={{ height: "350px" }}>
              <canvas id="bar-chart"></canvas>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BarCharts;
