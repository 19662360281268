// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {
    .print-footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      text-align: center;
    }
  }`, "",{"version":3,"sources":["webpack://./src/pages/expense.css"],"names":[],"mappings":"AAAA;IACI;MACE,eAAe;MACf,SAAS;MACT,WAAW;MACX,kBAAkB;IACpB;EACF","sourcesContent":["@media print {\r\n    .print-footer {\r\n      position: fixed;\r\n      bottom: 0;\r\n      width: 100%;\r\n      text-align: center;\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
