import React from "react";
import Chart from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import { getNonbillableExpense } from "../redux/slices/nonbill";
import { getInventory } from "../redux/slices/inventory";
const BillChart = () => {
  const dispatch = useDispatch();
  const isExpensesLoading = useSelector(
    (state) => state.nonbill.isExpensesLoading
  );
  const expenses = useSelector(
    (state) => state.nonbill.expenses
  );

  React.useEffect( () => {

     dispatch(getNonbillableExpense());
     console.clear()
    
     if(isExpensesLoading == undefined) {
      isExpensesLoading= false;
     }

   /* let config = {
      type: "bar",
      data: {
        labels: ['JAN','FEB', 'MAR', 'APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'],
        datasets: [
          {
            label: 'Non-Bill Expenses',
            backgroundColor: "red",
            borderColor: "red",
            data: [4, 3, 5,9],
            fill: false,
            barThickness: 8,
            borderWidth: 1,
          },
          
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: false,
          text: "Orders Chart",
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        legend: {
          labels: {
            fontColor: "rgba(0,0,0,.4)",
          },
          align: "end",
          position: "top",
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Month",
              },
              gridLines: {
                borderDash: [1],
                borderDashOffset: [1],
                color: "rgba(33, 37, 41, 0.3)",
                zeroLineColor: "rgba(33, 37, 41, 0.3)",
                zeroLineBorderDash: [1],
                zeroLineBorderDashOffset: [1],
              },
            },
          ],
          yAxes: [
            {
              position: 'top',
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Value",
              },
              gridLines: {
                borderDash: [2],
                drawBorder: false,
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.2)",
                zeroLineColor: "rgba(33, 37, 41, 0.15)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
              
            },
          ],
          borderColor: [
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)'
          ],
        },
      },
    };*/


    let config = {
      type: "bar",
      data: {
        labels: ['JAN','FEB', 'MAR', 'APR','MAY','JUN','JUL','AUG','SEP','OCT','NOV','DEC'],
        datasets: [
          {
            label: 'Non-Bill Expenses',
            backgroundColor: "red",
            borderColor: "red",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], // Initial data for each month
            fill: false,
            barThickness: 15,
            borderWidth: 2,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: false,
          text: "Orders Chart",
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        legend: {
          labels: {
            fontColor: "rgba(0,0,0,.4)",
          },
          align: "end",
          position: "top",
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Month",
              },
              gridLines: {
                borderDash: [1],
                borderDashOffset: [1],
                color: "rgba(33, 37, 41, 0.3)",
                zeroLineColor: "rgba(33, 37, 41, 0.3)",
                zeroLineBorderDash: [1],
                zeroLineBorderDashOffset: [1],
              },
            },
          ],
          yAxes: [
            {
              position: 'top',
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Value",
              },
              gridLines: {
                borderDash: [2],
                drawBorder: false,
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.2)",
                zeroLineColor: "rgba(33, 37, 41, 0.15)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
          borderColor: [
            'rgb(255, 99, 132)',
            'rgb(255, 159, 64)',
            'rgb(255, 205, 86)',
            'rgb(75, 192, 192)',
            'rgb(54, 162, 235)',
            'rgb(153, 102, 255)',
            'rgb(201, 203, 207)'
          ],
        },
      },
     };
     // Initialize an object to store accumulated values for each month
     let monthlyTotal = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]; // Index 0 represents JAN, index 1 represents FEB, and so on
expenses.forEach(item => {
 let month = parseInt(item.date.split('-')[1], 10);

 monthlyTotal[month - 1] += item.total_without_tax;
 
});
config.data.datasets[0].data = monthlyTotal;
     // Now config object is updated with the accumulated values for each month
     
    

    let ctx = document.getElementById("bar-chart").getContext("2d");
    window.myBar = new Chart(ctx, config);
  }, [dispatch]);
  return (
    <>
      <div className="w-full xl:w-11/12 px-4">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 border rounded">
          <div className="rounded-t mb-0 px-4 py-3 bg-transparent">
            <div className="flex flex-wrap items-center">
            </div>
          </div>
          <div className="p-5 flex-auto">
            {/* Chart */}
            <div className="relative" style={{ height: "350px" }}>
              <canvas id="bar-chart"></canvas>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default BillChart;
